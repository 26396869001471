import type { BlogTag } from '@wix/ambassador-blog-frontend-adapter-public-v2-post-feed-page/types';
import {
  GLOBALS,
  NormalizedCategory,
  getImageUrl,
} from '@wix/communities-blog-client-common';
import { NormalizedPost } from '../common/types';

export class PostPageRenderModel {
  private constructor(
    public post: NormalizedPost,
    public categories: NormalizedCategory[],
    public tags: BlogTag[],
    public counters: {
      viewCount: number;
      likeCount: number;
      commentsCount: number;
      isLiked: boolean;
    },
  ) {}

  static toWidgetData(model: PostPageRenderModel) {
    const heroImage =
      'src' in model.post.heroImage
        ? model.post.heroImage.src
        : model.post.heroImage;

    return {
      id: model.post.id,
      title: model.post.title || '',
      categories: this.filterPostCategories(
        model.categories,
        model.post.categoryIds,
      ),
      averageRating: model.post.averageRating,
      totalRatings: model.post.totalRatings,
      writer: {
        name: model.post.owner?.name ?? '',
        slug: model.post.owner?.slug ?? '',
        memberId: model.post.owner?.siteMemberId ?? '',
      },
      firstPublishedDate: model.post.firstPublishedDate,
      timeToRead: model.post.timeToRead,
      lastPublishedDate: model.post.lastPublishedDate,
      likeCount: model.counters.likeCount || 0,
      viewCount: model.counters.viewCount || 0,
      commentCount: model.counters.commentsCount || 0,
      isLiked: model.counters.isLiked || false,
      tags: model.tags,
      heroImageURL: getImageUrl({ image: heroImage }),
    };
  }

  private static filterPostCategories(
    allBlogCategories: NormalizedCategory[],
    postCategoryIds: string[],
  ): NormalizedCategory[] {
    return postCategoryIds.reduce((categories, id) => {
      const category = allBlogCategories.find(
        (blogCategory) => blogCategory._id === id,
      );

      if (!category) {
        return categories;
      }

      return [...categories, category];
    }, [] as NormalizedCategory[]);
  }
}
